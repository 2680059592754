.header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 175px;
  top: 0;
  left: 0;
  position: fixed;
  background-color: #181818;
}

.header > span {
  margin: 0;
  position: absolute;
  bottom: 0;
  width: 88%;
  border-bottom: 0.8px solid #404040;
}

.backdropImage {
  z-index: -1;
  top: 0;
  left: 0;
  box-sizing: border-box;
  padding: 0 7%;
  position: absolute;
  inset: 0;
  width: 100%;
  height: 130px;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-start;
  background-image: linear-gradient(
    270deg,
    rgba(70, 70, 70, 0.7) 27%,
    rgba(70, 70, 70, 0.2) 115.62%
  );
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.15));
}

.poster {
  z-index: 999;
  cursor: pointer;
  margin-bottom: -30px;
  margin-right: 4%;
  width: 79.2px;
  height: 120px;
  object-fit: cover;
  border-radius: 4px;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.5));
  transition: 300ms all;
  background-image: linear-gradient(
    270deg,
    rgb(34, 34, 34) 27%,
    rgb(44, 44, 44) 115.62%
  );
  position: relative;
  overflow: hidden;
}

.vodvilLogo {
  z-index: 9999;
  height: 27px;
  width: 27px;
  object-fit: cover;
  opacity: 0.6;
  position: absolute;
  right: 12px;
  top: 12px;
}

.productInfos {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
  margin-bottom: 9px;
}

.productInfos > .directorInfo {
  height: 10px;
  width: 70px;
  border-radius: 3px;
  background-image: linear-gradient(
    270deg,
    rgba(48, 48, 48, 1) 27%,
    rgba(34, 34, 34, 1) 115.62%
  );
  display: inline-block;
  position: relative;
  overflow: hidden;
}

.productInfos > .title {
  margin-top: 3px;
  height: 25px;
  width: 120px;
  border-radius: 3px;
  background-image: linear-gradient(
    270deg,
    rgba(48, 48, 48, 1) 27%,
    rgba(34, 34, 34, 1) 115.62%
  );
  display: inline-block;
  position: relative;
  overflow: hidden;
}

.Footer {
  position: fixed;
  bottom: 0;
  left: 0;
  min-height: 50px;
  width: 100%;
  border-top: 0.8px solid #404040;
  background-color: #181818;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0 5%;
}

.vodvilLogo {
  z-index: 9999;
  height: 27px;
  width: 27px;
  object-fit: cover;
  opacity: 0.6;
}

.Footer > .right {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.Footer > .right > .image {
  position: relative;
  overflow: hidden;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 11px;
  background-image: linear-gradient(
    270deg,
    rgba(48, 48, 48, 1) 27%,
    rgba(34, 34, 34, 1) 115.62%
  );
}

.Footer > .right > h5 {
  height: 25px;
  width: 120px;
  border-radius: 3px;
  background-image: linear-gradient(
    270deg,
    rgba(48, 48, 48, 1) 27%,
    rgba(34, 34, 34, 1) 115.62%
  );
  display: inline-block;
  position: relative;
  overflow: hidden;
}

.Footer > .left {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.Footer > .left > button {
  cursor: pointer;
  margin: 0 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 30px;
  min-height: 30px;
  border-radius: 50%;
  background: rgba(0, 0, 0, 0.8);
  backdrop-filter: blur(6px);
  transition: 300ms all;
}

.Footer > .right > h5::after,
.Footer > .right > .image::after,
.productInfos > .title::after,
.productInfos > .directorInfo::after,
.poster::after,
.backdropImage::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transform: translateX(-100%);
  background-image: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0) 0,
    rgba(121, 121, 121, 0.1) 20%,
    rgba(87, 87, 87, 0.1) 60%,
    rgba(255, 255, 255, 0)
  );
  -webkit-animation: shimmer 2s infinite;
  animation: shimmer 2s infinite;
  content: "";
}

@-webkit-keyframes shimmer {
  100% {
    transform: translateX(100%);
  }
}
@keyframes shimmer {
  100% {
    transform: translateX(100%);
  }
}
